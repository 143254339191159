import { React } from "react";
import "../styles/Home.css";

const Home = () => {
  // Find Out More Button Redirect
  const handleRedirect = () => {
    window.location.href = "#services";
  };

  return (
    <div className="home" id="home">
      <div className="cover">
        <div className="left">
          <div className="header">
            <h1>UNLEASH YOUR</h1>
            <h1 className="innovation-text">INNOVATION</h1>
          </div>
          <h2>Custom Software & Hardware</h2>
          <div className="list">
            <svg
              class="check-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
            <p>Connecting devices into smart ecosystems.</p>
          </div>
          <div className="list">
            <svg
              class="check-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
            <p>Tailored hardware and software solutions.</p>
          </div>
          <div className="list">
            <svg
              class="check-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
            <p>Integrating high-performance hardware seamlessly.</p>
          </div>
          <div className="list">
            <svg
              class="check-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
            <p>Building efficient, secure applications.</p>
          </div>
          <div className="list">
            <svg
              class="check-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
            <p>Boosting system performance and reliability.</p>
          </div>
          <div className="list">
            <svg
              class="check-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
            <p>Complete services from design to deployment.</p>
          </div>
          <button
            onClick={() => {
              handleRedirect();
            }}
          >
            Find out more
          </button>
        </div>
        <div className="right">
          <div className="hardware-card card" />
          <div className="software-card card" />
        </div>
      </div>
    </div>
  );
};

export default Home;
