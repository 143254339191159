export const ServicesData = [
    {
        header: 'Consultancy',
        text: 'We offer expert consultancy in IoT, custom engineering, and system integration, providing strategic guidance, architecture design, and project support to ensure seamless, scalable, and secure solutions for your business.',
    },
    {
        header: 'PCB Design',
        text: 'We provide professional PCB design services, optimizing layouts for performance, reliability, and manufacturability, ensuring seamless integration with hardware and delivering efficient, high- quality solutions tailored to your project’s needs.',
    },
    {
        header: 'Modern Web Design',
        text: 'We specialize in modern web design, creating visually stunning, user-friendly websites optimized for performance and responsiveness, ensuring an engaging online experience that effectively represents your brand and meets user needs.',
    },
    {
        header: 'Multi-platform App Design',
        text: 'We design multi-platform applications that deliver consistent, intuitive user experiences across devices, leveraging responsive design principles to ensure optimal performance and functionality on mobile, tablet, and desktop platforms.',
    },
    {
        header: 'Hardware Design and Production',
        text: 'We offer comprehensive hardware design and production services, focusing on innovative solutions, rigorous testing, and efficient manufacturing processes to deliver high-quality, reliable products tailored to your specifications.',
    },
    {
        header: 'Telemetry Monitoring and Control',
        text: 'We provide telemetry monitoring and control solutions that enable real-time data collection, analysis, and remote management, ensuring enhanced visibility and operational efficiency for your critical systems and processes.',
    }
]

export const FaqData = [
    {
        question: `What types of custom engineering solutions do you offer?`,
        answer: 'We specialize in providing tailored hardware and software solutions, including embedded systems design, firmware development, IoT solutions, custom electronics design, and full-stack software development.'
    },
    {
        question: 'What programming languages and frameworks do you use?',
        answer: 'We work with a variety of languages including but not limited to Python, Java, JavaScript, C, and frameworks like React and React Native.'
    },
    {
        question: `Can you assist with sourcing components and manufacturing?`,
        answer: 'Yes, we assist with component selection, sourcing, and work closely with manufacturing partners to ensure quality and scalability in production.'
    },
    {
        question: 'What types of software solutions do you provide?',
        answer: 'We offer custom software development including embedded software/firmware, mobile apps, desktop applications, cloud solutions, and web applications, tailored to your hardware and business needs.'
    },
    {
        question: 'What is your typical project timeline?',
        answer: 'Timelines vary depending on the complexity of the project. However, we aim to provide an initial estimate during the consultation phase, followed by detailed project milestones and regular updates throughout the development process.'
    },
    {
        question: 'What role does IoT play in your custom solutions?',
        answer: 'We offer IoT-enabled solutions that allow for real-time data collection, remote monitoring, and smart decision-making. Our team can integrate IoT into your products to enhance their connectivity and functionality.'
    }

]

export const PortfolioData = [
    {
        header: 'Take a look at our Portfolio',
        subheader: 'Here are some of our projects',
        text: 'FaustLink has designed and built custom solutions for various clients that can be categorised into three options: Software focused, Hardware focused, and a complete Hardware and Software Solution.We have a team of experts that can assist with the design process of hardware and select software frameworks if your business is in need of any guidance.We can design and manufacture PCBs for specific purposes at any point in the design process.Using modern stacks, we can design a web experience to meet your specific requirements.'
    }
]