import { React, useEffect, useState } from 'react'
import '../styles/Team.css'
import AlexCard from '../assets/team/cards/alex-card.svg'
import AlbertCard from '../assets/team/cards/albert-card.svg'
import ChrisCard from '../assets/team/cards/chris-card.svg'
import SheldonCard from '../assets/team/cards/sheldon-card.svg'
import RaynardCard from '../assets/team/cards/raynard-card.svg'
import SebastianCard from '../assets/team/cards/sebastian-card.svg'

const Team = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 650)
  const [displayTeam, setDisplayTeam] = useState(false) // View More Button State 

  // Window Resizing Detection 
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className='team' id='team'>
      <div className='team-container'>
        <div className='top'>
          <h1>Meet the Team</h1>
        </div>
        {!isMobile ?
          <div className='bottom'>
            <div className='team-card'> <img src={AlexCard} /> </div>
            <div className='team-card'> <img src={SheldonCard} /> </div>
            <div className='team-card'> <img src={ChrisCard} /> </div>
            <div className='team-card'> <img src={AlbertCard} /> </div>
            <div className='team-card'> <img src={RaynardCard} /> </div>
            <div className='team-card'> <img src={SebastianCard} /> </div>
          </div>
          :
          <div className='bottom' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '40px' }}>
            <div className='team-card'> <img src={AlexCard} /> </div>
            {displayTeam ?
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '40px' }}>
                <div className='team-card'> <img src={SheldonCard} /> </div>
                <div className='team-card'> <img src={ChrisCard} /> </div>
                <div className='team-card'> <img src={AlbertCard} /> </div>
                <div className='team-card'> <img src={RaynardCard} /> </div>
                <div className='team-card'> <img src={SebastianCard} /> </div>
                <div onClick={() => setDisplayTeam(!displayTeam)} className='view-btn'>
                  View Less
                </div>
              </div>
              :
              <div onClick={() => { setDisplayTeam(!displayTeam) }} className='view-btn'>
                View More
              </div>
            }
          </div>
        }
      </div>
      <svg id="wave-bottom" style={{ transform: 'rotate(180deg)', transition: '0.3s' }} viewBox="0 0 1440 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop stop-color="rgba(66, 139, 202, 1)" offset="0%"></stop>
            <stop stop-color="rgba(66, 139, 202, 1)" offset="100%"></stop>
          </linearGradient>
        </defs>
        <path style={{ transform: 'translate(0, 0px)', opacity: '1' }} fill="url(#sw-gradient-0)" d="M0,70L48,61.7C96,53,192,37,288,33.3C384,30,480,40,576,46.7C672,53,768,57,864,50C960,43,1056,27,1152,18.3C1248,10,1344,10,1440,20C1536,30,1632,50,1728,55C1824,60,1920,50,2016,51.7C2112,53,2208,67,2304,71.7C2400,77,2496,73,2592,70C2688,67,2784,63,2880,53.3C2976,43,3072,27,3168,18.3C3264,10,3360,10,3456,11.7C3552,13,3648,17,3744,25C3840,33,3936,47,4032,48.3C4128,50,4224,40,4320,36.7C4416,33,4512,37,4608,41.7C4704,47,4800,53,4896,60C4992,67,5088,73,5184,63.3C5280,53,5376,27,5472,28.3C5568,30,5664,60,5760,68.3C5856,77,5952,63,6048,53.3C6144,43,6240,37,6336,36.7C6432,37,6528,43,6624,45C6720,47,6816,43,6864,41.7L6912,40L6912,100L6864,100C6816,100,6720,100,6624,100C6528,100,6432,100,6336,100C6240,100,6144,100,6048,100C5952,100,5856,100,5760,100C5664,100,5568,100,5472,100C5376,100,5280,100,5184,100C5088,100,4992,100,4896,100C4800,100,4704,100,4608,100C4512,100,4416,100,4320,100C4224,100,4128,100,4032,100C3936,100,3840,100,3744,100C3648,100,3552,100,3456,100C3360,100,3264,100,3168,100C3072,100,2976,100,2880,100C2784,100,2688,100,2592,100C2496,100,2400,100,2304,100C2208,100,2112,100,2016,100C1920,100,1824,100,1728,100C1632,100,1536,100,1440,100C1344,100,1248,100,1152,100C1056,100,960,100,864,100C768,100,672,100,576,100C480,100,384,100,288,100C192,100,96,100,48,100L0,100Z">
        </path>
      </svg>
    </div >
  )
}

export default Team
