import React, { useState } from 'react'
import '../styles/Services.css'
import { ServicesData } from '../data/MainData'
import { useInView } from 'react-intersection-observer';
import { ReactComponent as MessageIcon } from '../assets/icons/message.svg'
import { ReactComponent as MobileIcon } from '../assets/icons/mobile.svg'
import { ReactComponent as TelemetryIcon } from '../assets/icons/telemetry.svg'
import { ReactComponent as WebIcon } from '../assets/icons/web.svg'
import { ReactComponent as ChipIcon } from '../assets/icons/chip.svg'
import { ReactComponent as HardwareIcon } from '../assets/icons/hardware.svg'

const Services = () => {

  // Hook for observing if the testimonials are in view
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2, // Percentage of the component in the viewport before triggering
  });

  // Services Icon List
  const servicesIcons = [
    <MessageIcon />,
    <ChipIcon />,
    <WebIcon />,
    <MobileIcon />,
    <HardwareIcon />,
    <TelemetryIcon />,
  ]

  return (
    <div className='services' id='services' ref={ref}>
      <svg id="wave" style={{ transform: 'rotate(0deg)', transition: '0.3s' }} viewBox="0 0 1440 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
          <stop stop-color="rgba(66, 139, 202, 1)" offset="0%"></stop>
          <stop stop-color="rgba(66, 139, 202, 1)" offset="100%"></stop>
        </linearGradient>
        </defs>
        <path style={{ transform: 'translate(0, 0px)', opacity: '1' }} fill="url(#sw-gradient-0)" d="M0,70L48,61.7C96,53,192,37,288,33.3C384,30,480,40,576,50C672,60,768,70,864,70C960,70,1056,60,1152,46.7C1248,33,1344,17,1440,15C1536,13,1632,27,1728,33.3C1824,40,1920,40,2016,33.3C2112,27,2208,13,2304,8.3C2400,3,2496,7,2592,11.7C2688,17,2784,23,2880,35C2976,47,3072,63,3168,73.3C3264,83,3360,87,3456,73.3C3552,60,3648,30,3744,28.3C3840,27,3936,53,4032,63.3C4128,73,4224,67,4320,68.3C4416,70,4512,80,4608,73.3C4704,67,4800,43,4896,33.3C4992,23,5088,27,5184,38.3C5280,50,5376,70,5472,65C5568,60,5664,30,5760,30C5856,30,5952,60,6048,61.7C6144,63,6240,37,6336,25C6432,13,6528,17,6624,30C6720,43,6816,67,6864,78.3L6912,90L6912,100L6864,100C6816,100,6720,100,6624,100C6528,100,6432,100,6336,100C6240,100,6144,100,6048,100C5952,100,5856,100,5760,100C5664,100,5568,100,5472,100C5376,100,5280,100,5184,100C5088,100,4992,100,4896,100C4800,100,4704,100,4608,100C4512,100,4416,100,4320,100C4224,100,4128,100,4032,100C3936,100,3840,100,3744,100C3648,100,3552,100,3456,100C3360,100,3264,100,3168,100C3072,100,2976,100,2880,100C2784,100,2688,100,2592,100C2496,100,2400,100,2304,100C2208,100,2112,100,2016,100C1920,100,1824,100,1728,100C1632,100,1536,100,1440,100C1344,100,1248,100,1152,100C1056,100,960,100,864,100C768,100,672,100,576,100C480,100,384,100,288,100C192,100,96,100,48,100L0,100Z">
        </path>
      </svg>
      <div className='services-content'>
        <div className='header'>
          <h1 className='title'>
            Our Services
          </h1>
          <h3>Explore Our Customized Hardware and Software Solutions Designed to Empower Your Business.</h3>
        </div>

        <div className={inView ? 'animation-activated services-container' : 'services-container'}>
          {ServicesData?.map((item, index) => {
            return (
              <div className='service-card'
                style={{
                  animationDelay: `${index * 0.5}s`, // Adjust delay to match animation duration
                }}
                key={index}
              >
                <div className='top'>
                  <div
                    className='circle'
                    style={{
                      animationDelay: `${index * 0.5}s`
                    }}
                  >
                    <div className='icon'>
                      {servicesIcons[index]}
                    </div>
                  </div>
                  <hr className='line'
                    style={{
                      animationDelay: `${index * 0.5}s`
                    }}
                  />
                </div>
                <div className='text'>
                  <h3>{item.header}</h3>
                  <p>{item.text}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Services
