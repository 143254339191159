import { React, useState, useEffect } from 'react'
import '../styles/Contact.css'
import { Puff } from 'react-loader-spinner';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { sendContactEmail, verifyCaptcha } from '../api/ContactAPI'

const Contact = ({ toggle }) => {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [message, setMessage] = useState()
  const [isVerifying, setIsVerifying] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    const storedDate = localStorage.getItem('email');
    const date = new Date(storedDate);
    const currentDate = new Date()

    if (date === undefined) {
      setDisableSubmit(false)
    }

    const differenceInMs = currentDate.getTime() - date.getTime();
    const differenceInMinutes = differenceInMs / (1000 * 60);

    if (differenceInMinutes >= 2) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }

  }, [])

  const handleInputChange = (value, type) => {
    switch (type) {
      case 'name':
        setName(value)
        break;
      case 'email':
        setEmail(value)
        break;
      case 'phone':
        setPhone(value)
        break;
      case 'message':
        setMessage(value)
        break;
    }
  }

  const clearFormData = () => {
    setName('')
    setEmail('')
    setPhone('')
    setMessage('')
  }

  const handleSubmit = async () => {
    localStorage.clear();
    const emailRegex = /.+@.+\..+/;
    if (email && name !== '') {
      const isValidEmail = emailRegex.test(email)
      if (isValidEmail) {
        setIsVerifying(true)
        try {
          const recaptchaToken = await executeRecaptcha("submit_contact_us")
          const verifyResponse = await verifyCaptcha(recaptchaToken)
          if (verifyResponse >= 0.5) {
            const res = await sendContactEmail(name, email, phone, message)
            setTimeout(() => {
              setIsVerifying(false)
              if (res.status === 200) {
                const date = new Date();
                localStorage.setItem('email', date.toISOString())
                toggle(true, 'positive', 'Email Sent')
                setDisableSubmit(true)
              } else {
                toggle(true, 'negative', 'Email Not Sent (Error has occured)')
              }
            }, 2000)
            clearFormData();
          } else {
            setIsVerifying(false)
            toggle(true, 'negative', 'Captcha Verification Failed')
          }
        } catch (e) {
          console.log("Error: " + e)
        }
      } else {
        toggle(true, 'neutral', 'Use A Valid Email Address')
      }
    } else {
      toggle(true, 'negative', 'Please Fill In All Fields')
    }
  }

  return (
    <div className="contact" id='contact'>
      <div className="left">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d106001.61980454784!2d18.511186116406243!3d-33.8754704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcdb3be2d00a44f%3A0x67cdaa13139caa33!2sFaustLink!5e0!3m2!1sen!2sza!4v1718095904587!5m2!1sen!2sza"
          width="100%"
          height="100%"
          className='map'
          allowfullscreen=""
          lang='en'
          loading='lazy'
          outline='none'
          referrerpolicy="no-referrer-when-downgrade"
          title="FaustLink Location"
        />
      </div>
      <div className="right">

        {isVerifying ?
          <div className='loader-form'>
            <Puff
              visible={true}
              height="100"
              width="100"
              color="#428bca"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
            <h1>Verifying your submission...</h1>
          </div>
          :
          <div className='form'>
            <h1>Contact Us</h1>
            <h3>Fill out the form below to get in touch with us</h3>
            <input type="text" placeholder='Name' onChange={(e) => handleInputChange(e.target.value, 'name')} />
            <input type="email" placeholder='Email' onChange={(e) => handleInputChange(e.target.value, 'email')} />
            <input type="tel" placeholder='Phone (Optional)' onChange={(e) => handleInputChange(e.target.value, 'phone')} />
            <textarea placeholder='Message' onChange={(e) => handleInputChange(e.target.value, 'message')} />
            <button type='submit' onClick={() => handleSubmit()} disabled={disableSubmit}>
              Send
              <span className='tooltiptext'>Please wait 2 minutes before sending another email.</span>
            </button>
          </div>
        }
      </div>
    </div>
  );
}


export default Contact;
