import { React, useState, useEffect } from 'react'
import '../styles/FAQ.css'
import { FaqData } from '../data/MainData'
import { ReactComponent as QuestionMark } from '../assets/icons/question-mark.svg'
import { useSpring, animated } from '@react-spring/web'
import { useInView } from 'react-intersection-observer';

const FAQ = () => {
  const [isWrapped, setIsWrapped] = useState(window.innerWidth <= 1000);
  const [state, toggle] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      setIsWrapped(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Hook for observing if the faq's are in view
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5, // Percentage of the component in the viewport before triggering
  });

  // Bouncy Animation that will run when the QuestionMark Comonent is clicked or in view
  const { x } = useSpring({
    from: { x: 0 },
    x: state ? 1 : 0,
    config: { duration: 1000 },
  })


  return (
    <div className='faq-section' id='faq' >
      <div className='faq-header'>
        <h1 className='faq-title'>Frequently Asked Questions</h1>
        <h3>Here are some of our most frequently asked questions</h3>
      </div>
      <div className='faq-container' ref={ref} >
        <div className='faq-left'>

          <div className='faq-text'>
            {FaqData?.slice(0, 3).map((item, index) => {
              return (
                <div className='faq-card' key={index}>
                  <div className='icon-container'>
                    <animated.div className='circle' onClick={() => toggle(!state)}
                      style={{
                        opacity: x.to({ range: [0, 1], output: [1, 1] }),
                        scale: x.to({
                          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                          output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
                        }),
                      }}>
                      <QuestionMark />
                    </animated.div>
                    <hr className={index == 2 && !isWrapped ? 'no-line' : 'line'} />
                  </div>
                  <div className='text-container'>

                    <h2>{item.question}</h2>
                    <p>{item.answer}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className='faq-right'>

          <div className='faq-text'>
            {FaqData?.slice(3, 6).map((item, index) => {
              return (
                <div className='faq-card' key={index}>
                  <div className='icon-container'>
                    <animated.div className='circle' onClick={() => toggle(!state)}
                      style={{
                        opacity: x.to({ range: [0, 1], output: [1, 1] }),
                        scale: x.to({
                          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                          output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
                        }),
                      }}>
                      <QuestionMark />
                    </animated.div>
                    <hr className={index == 2 ? 'no-line' : 'line'} />
                  </div>
                  <div className='text-container'>

                    <h2>{item.question}</h2>
                    <p>{item.answer}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

    </div >
  )
}

export default FAQ
