import axios from 'axios';

const API_URL = 'https://n7pi8nxre7.execute-api.af-south-1.amazonaws.com/prod'

export const sendContactEmail = async (name, email, cellNo, query) => {
    const url = `${API_URL}/contact-us`;
    try {
        const res = await axios.post(url, {
            name,
            email,
            cellNo,
            query
        });
        return res;
    } catch (error) {
        console.error('Error fetching data: ', error);
        return null;
    }
}

export const verifyCaptcha = async (token) => {
    const url = `${API_URL}/verify-captcha`;
    try {
        const res = await axios.post(url, {
            token
        });
        return res.data;
    } catch (error) {
        console.error('Error fetching data: ', error);
        return null;
    }

}
