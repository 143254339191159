import React from 'react'
import logo from '../assets/white-faustlink.png'
import '../styles/Footer.css'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { ReactComponent as MailIcon } from '../assets/icons/mail.svg'
import { ReactComponent as PhoneIcon } from '../assets/icons/phone.svg'
import { ReactComponent as LocationIcon } from '../assets/icons/location.svg'

const Footer = () => {

    const handleRedirect = (site) => {
        switch (site) {
            case 'linkedin':
                window.location.href = 'https://za.linkedin.com/company/faustlink';
                break
            case 'facebook':
                window.location.href = 'https://m.facebook.com/Faustlink/';
                break
            default:
                break
        }
    }

    return (
        <div className='footer'>
            <svg id="wave" style={{ transform: 'rotate(0deg)', transition: '0.3s' }} viewBox="0 0 1440 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
                        <stop stop-color="rgba(66, 139, 202, 1)" offset="0%">
                        </stop>
                        <stop stop-color="rgba(66, 139, 202, 1)" offset="100%">
                        </stop>
                    </linearGradient>
                </defs>
                <path style={{ transform: 'translate(0, 0px)', opacity: '1' }} fill="url(#sw-gradient-0)" d="M0,0L48,13.3C96,27,192,53,288,66.7C384,80,480,80,576,71.7C672,63,768,47,864,38.3C960,30,1056,30,1152,35C1248,40,1344,50,1440,45C1536,40,1632,20,1728,10C1824,0,1920,0,2016,6.7C2112,13,2208,27,2304,40C2400,53,2496,67,2592,61.7C2688,57,2784,33,2880,20C2976,7,3072,3,3168,8.3C3264,13,3360,27,3456,30C3552,33,3648,27,3744,35C3840,43,3936,67,4032,68.3C4128,70,4224,50,4320,48.3C4416,47,4512,63,4608,65C4704,67,4800,53,4896,48.3C4992,43,5088,47,5184,45C5280,43,5376,37,5472,36.7C5568,37,5664,43,5760,48.3C5856,53,5952,57,6048,58.3C6144,60,6240,60,6336,56.7C6432,53,6528,47,6624,36.7C6720,27,6816,13,6864,6.7L6912,0L6912,100L6864,100C6816,100,6720,100,6624,100C6528,100,6432,100,6336,100C6240,100,6144,100,6048,100C5952,100,5856,100,5760,100C5664,100,5568,100,5472,100C5376,100,5280,100,5184,100C5088,100,4992,100,4896,100C4800,100,4704,100,4608,100C4512,100,4416,100,4320,100C4224,100,4128,100,4032,100C3936,100,3840,100,3744,100C3648,100,3552,100,3456,100C3360,100,3264,100,3168,100C3072,100,2976,100,2880,100C2784,100,2688,100,2592,100C2496,100,2400,100,2304,100C2208,100,2112,100,2016,100C1920,100,1824,100,1728,100C1632,100,1536,100,1440,100C1344,100,1248,100,1152,100C1056,100,960,100,864,100C768,100,672,100,576,100C480,100,384,100,288,100C192,100,96,100,48,100L0,100Z"></path>
            </svg>
            <div className='footer-container'>

                <div className='logo-container'>
                    <img src={logo} alt='Faustlink Logo' className='logo' />
                </div>
                <div className='footer-content'>


                    <div className='details-container'>
                        <div className='top'>
                            <div className='details'>
                                <LocationIcon className='detail-icon' />
                                <p> 5 Shale Street, Stellenridge, Cape Town, South Africa</p>
                            </div>
                        </div>

                        <div className='bottom'>
                            <div className='details'>
                                <PhoneIcon className='detail-icon' />
                                <p>+27 87 265 7851</p>
                            </div>
                            <div className='details'>
                                <MailIcon className='detail-icon' />
                                <p>contact-us@faustlink.com</p>
                            </div>
                        </div>

                    </div>
                    <div className='socials'>
                        <div className='icon-container'>
                            <LinkedInIcon onClick={() => handleRedirect('linkedin')} />
                        </div>
                        <div className='icon-container'>
                            <FacebookIcon onClick={() => handleRedirect('facebook')} />
                        </div>
                    </div>
                </div>

            </div>
            <div className='copyright-container'>
                <p>© FaustLink (Pty) Ltd. All Rights Reserved</p>
            </div>
        </div >
    )
}

export default Footer