import "./App.css";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import FAQ from "./components/FAQ";
import Team from "./components/Team";
import AlertModal from "./modals/AlertModal";
import { useState } from "react";

function App() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({ alertType: "", message: "" });

  const toggleAlert = (show, alertType, message) => {
    setShowAlert(show);
    if (show) {
      setAlertProps({ alertType, message });
    }
  };

  return (
    <div className="app">
      <AlertModal show={showAlert} props={alertProps} toggle={toggleAlert} />
      <Navbar />
      <Home />
      <Services />
      <Team />
      <FAQ />
      <Contact toggle={toggleAlert} />
      <Footer />
    </div>
  );
}

export default App;
