import { React, useState, useEffect } from "react";
import "../styles/Navbar.css";
import logo from "../assets/FL_Logo.png";
import { Link } from "react-scroll";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 820);
  const [displayMenu, setDisplayMenu] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 820);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={isMobile ? "mobile-navbar" : "navbar"}>
      <div className="navbar-logo">
        <img src={logo} alt="logo" loading="eager" />
      </div>

      {isMobile ? (
        <div className="mobile-navbar-links">
          <div className="menu-icon">
            <div
              id="nav-icon3"
              className={displayMenu ? "open" : "close"}
              onClick={() => {
                setDisplayMenu(!displayMenu);
              }}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          {displayMenu && (
            <ul className="links">
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <li>Home</li>
              </Link>
              <Link
                to="services"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <li>Services</li>
              </Link>
              <Link
                to="team"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <li>Team</li>
              </Link>
              <Link
                to="faq"
                spy={true}
                smooth={true}
                offset={-40}
                duration={500}
              >
                <li>FAQ</li>
              </Link>
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
              >
                <li>Contact</li>
              </Link>
            </ul>
          )}
        </div>
      ) : (
        <ul className="navbar-links">
          <Link to="home" spy={true} smooth={true} offset={-70} duration={500}>
            <li>Home</li>
          </Link>
          <Link
            to="services"
            spy={true}
            smooth={true}
            offset={10}
            duration={500}
          >
            <li>Services</li>
          </Link>
          <Link to="team" spy={true} smooth={true} offset={-70} duration={500}>
            <li>Team</li>
          </Link>
          <Link to="faq" spy={true} smooth={true} offset={-30} duration={500}>
            <li>FAQ</li>
          </Link>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-190}
            duration={500}
          >
            <li>Contact</li>
          </Link>
        </ul>
      )}
    </div>
  );
};

export default Navbar;
