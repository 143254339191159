import { React, useEffect, useState } from 'react'
import '../styles/modals/AlertModal.css'

const AlertModal = ({ show, props, toggle }) => {
    const [alertID, setAlertID] = useState("")

    useEffect(() => {
        if (!show) return
        setAlertID('show-alert')
        setTimeout(() => {
            setAlertID("hide-alert")
            toggle(false)
        }, 3000)
    }, [show])

    const icon = () => {
        switch (props.alertType) {
            case 'positive':
                return (
                    <div className='icon positive-icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </div>
                )
                break
            case 'neutral':
                return (
                    <div className='icon neutral-icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                        </svg>
                    </div>
                )
                break
            case 'negative':
                return (
                    <div className='icon negative-icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </div>
                )
                break
        }
    }

    return (
        <div className={`alert-container`} id={alertID}>
            {icon()}
            <h2 className='message'>{props.message}</h2>
        </div>
    )
}

export default AlertModal